Ember.TEMPLATES["javascripts/discourse/connectors/bread-crumbs-right/solved-status-filter"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"siteSettings\",\"solved_enabled\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"combo-box\",null,[[\"class\",\"content\",\"value\",\"valueProperty\",\"onChange\",\"options\"],[\"solved-status-filter\",[24,[\"statuses\"]],[24,[\"status\"]],\"value\",[28,\"action\",[[23,0,[]],\"changeStatus\"],null],[28,\"hash\",null,[[\"caretDownIcon\",\"caretUpIcon\"],[\"caret-right\",\"caret-down\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/bread-crumbs-right/solved-status-filter"}});
define("discourse/plugins/discourse-solved/discourse/connectors/bread-crumbs-right/solved-status-filter", ["exports", "I18n", "discourse-common/lib/get-owner"], function (_exports, _I18n, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(args, component) {
      var router = (0, _getOwner.getOwner)(this).lookup("router:main");

      if (!component.siteSettings.show_filter_by_solved_status || router.currentPath === "discovery.categories") {
        return false;
      } else if (component.siteSettings.allow_solved_on_all_topics) {
        return true;
      } else {
        var controller = (0, _getOwner.getOwner)(this).lookup("controller:navigation/category");
        return controller && controller.get("category.enable_accepted_answers");
      }
    },
    setupComponent: function setupComponent(args, component) {
      var statuses = ["all", "solved", "unsolved"].map(function (status) {
        return {
          name: _I18n.default.t("solved.topic_status_filter.".concat(status)),
          value: status
        };
      });
      component.set("statuses", statuses);
      var queryStrings = window.location.search;

      if (queryStrings.match(/solved=yes/)) {
        component.set("status", "solved");
      } else if (queryStrings.match(/solved=no/)) {
        component.set("status", "unsolved");
      } else {
        component.set("status", "all");
      }
    },
    actions: {
      changeStatus: function changeStatus(newStatus) {
        var router = (0, _getOwner.getOwner)(this).lookup("router:main");

        if (newStatus && newStatus !== "all") {
          newStatus = newStatus === "solved" ? "yes" : "no";
        }

        router.transitionTo({
          queryParams: {
            solved: newStatus
          }
        });
      }
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/category-custom-settings/solved-settings"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"siteSettings\",\"allow_solved_on_all_topics\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"solved.title\"],null],false],[9],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"enable-accepted-answer\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"checkbox-label\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[28,\"readonly\",[[24,[\"category\",\"enable_accepted_answers\"]]],null],[28,\"action\",[[23,0,[]],\"onChangeSetting\"],[[\"value\"],[\"target.checked\"]]]]]],false],[0,\"\\n        \"],[1,[28,\"i18n\",[\"solved.allow_accepted_answers\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/category-custom-settings/solved-settings"}});
define("discourse/plugins/discourse-solved/discourse/connectors/category-custom-settings/solved-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    actions: {
      onChangeSetting: function onChangeSetting(value) {
        this.set("category.custom_fields.enable_accepted_answers", value ? "true" : "false");
      }
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/topic-after-cooked/solved-panel"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"topic\",\"accepted_answer\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"solved\"],[8],[0,\"\\n    \"],[1,[28,\"html-safe\",[[24,[\"topic\",\"acceptedAnswerHtml\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/topic-after-cooked/solved-panel"}});
Ember.TEMPLATES["javascripts/discourse/connectors/topic-navigation/no-answer"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[4,\"topic-navigation-popup\",null,[[\"popupId\",\"dismissDuration\"],[\"solved-notice\",[24,[\"oneWeek\"]]]],{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"solved.no_answer.title\"],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"solved.no_answer.description\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/topic-navigation/no-answer"}});
define("discourse/plugins/discourse-solved/discourse/connectors/topic-navigation/no-answer", ["exports", "@ember/runloop", "discourse-common/config/environment"], function (_exports, _runloop, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ONE_WEEK = 7 * 24 * 60 * 60 * 1000; // milliseconds

  var MAX_DURATION_WITH_NO_ANSWER = ONE_WEEK;
  var DISPLAY_DELAY = (0, _environment.isTesting)() ? 0 : 2000;
  var _default = {
    shouldRender: function shouldRender(args, component) {
      return !component.site.mobileView;
    },
    setupComponent: function setupComponent(args, component) {
      component.set("show", false);
      component.setProperties({
        oneWeek: ONE_WEEK,
        show: false
      });
      (0, _runloop.later)(function () {
        if (!component.element || component.isDestroying || component.isDestroyed) {
          return;
        }

        var topic = args.topic;
        var currentUser = component.currentUser; // show notice if:
        // - user can accept answer
        // - it does not have an accepted answer
        // - topic is old
        // - topic has at least one reply from another user that can be accepted

        if (!topic.accepted_answer && currentUser && topic.user_id === currentUser.id && moment() - moment(topic.created_at) > MAX_DURATION_WITH_NO_ANSWER && topic.postStream.posts.some(function (post) {
          return post.user_id !== currentUser.id && post.can_accept_answer;
        })) {
          component.set("show", true);
        }
      }, DISPLAY_DELAY);
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/user-activity-bottom/solved-list"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"route\"],[\"userActivity.solved\"]],{\"statements\":[[0,\"  \"],[1,[28,\"d-icon\",[\"check-square\"],null],false],[0,\" \"],[1,[28,\"i18n\",[\"solved.title\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-activity-bottom/solved-list"}});
Ember.TEMPLATES["javascripts/discourse/connectors/user-card-metadata/accepted-answers"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"user\",\"accepted_answers\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"desc\"],[8],[1,[28,\"i18n\",[\"solutions\"],null],false],[9],[0,\"\\n    \"],[1,[24,[\"user\",\"accepted_answers\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-card-metadata/accepted-answers"}});
Ember.TEMPLATES["javascripts/discourse/connectors/user-summary-stat/solved-count"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"route\"],[\"userActivity.solved\"]],{\"statements\":[[0,\"  \"],[1,[28,\"user-stat\",null,[[\"value\",\"label\",\"icon\"],[[24,[\"model\",\"solved_count\"]],\"solved.solution_summary\",\"check-square\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/user-summary-stat/solved-count"}});
define("discourse/plugins/discourse-solved/discourse/initializers/add-topic-list-class", ["exports", "discourse-common/utils/decorators", "discourse/components/topic-list-item"], function (_exports, _decorators, _topicListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = {
    name: "add-topic-list-class",
    initialize: function initialize() {
      var _dec, _obj;

      _topicListItem.default.reopen((_dec = (0, _decorators.default)(), (_obj = {
        unboundClassNames: function unboundClassNames() {
          var classList = this._super.apply(this, arguments);

          if (this.topic.has_accepted_answer) {
            classList += " status-solved";
          }

          return classList;
        }
      }, (_applyDecoratedDescriptor(_obj, "unboundClassNames", [_dec], Object.getOwnPropertyDescriptor(_obj, "unboundClassNames"), _obj)), _obj)));
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-solved/discourse/initializers/extend-for-solved-button", ["exports", "I18n", "discourse/models/topic", "discourse/models/user", "discourse/raw-views/topic-status", "discourse/helpers/topic-status-icons", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/widgets/post-cooked", "discourse/lib/utilities", "discourse-common/lib/icon-library", "@ember/object"], function (_exports, _I18n, _topic, _user, _topicStatus, _topicStatusIcons, _ajaxError, _pluginApi, _ajax, _postCooked, _utilities, _iconLibrary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function clearAccepted(topic) {
    var posts = topic.get("postStream.posts");
    posts.forEach(function (post) {
      if (post.get("post_number") > 1) {
        post.setProperties({
          accepted_answer: false,
          can_accept_answer: true,
          can_unaccept_answer: false
        });
      }
    });
  }

  function unacceptPost(post) {
    if (!post.can_unaccept_answer) {
      return;
    }

    var topic = post.topic;
    post.setProperties({
      can_accept_answer: true,
      can_unaccept_answer: false,
      accepted_answer: false
    });
    topic.set("accepted_answer", undefined);
    (0, _ajax.ajax)("/solution/unaccept", {
      type: "POST",
      data: {
        id: post.id
      }
    }).catch(_ajaxError.popupAjaxError);
  }

  function acceptPost(post) {
    var topic = post.topic;
    clearAccepted(topic);
    post.setProperties({
      can_unaccept_answer: true,
      can_accept_answer: false,
      accepted_answer: true
    });
    topic.set("accepted_answer", {
      username: post.username,
      post_number: post.post_number,
      excerpt: post.cooked
    });
    (0, _ajax.ajax)("/solution/accept", {
      type: "POST",
      data: {
        id: post.id
      }
    }).catch(_ajaxError.popupAjaxError);
  }

  function initializeWithApi(api) {
    var currentUser = api.getCurrentUser();

    _topicStatusIcons.default.addObject(["has_accepted_answer", "far-check-square", "solved"]);

    api.includePostAttributes("can_accept_answer", "can_unaccept_answer", "accepted_answer");

    if (api.addDiscoveryQueryParam) {
      api.addDiscoveryQueryParam("solved", {
        replace: true,
        refreshModel: true
      });
    }

    api.addPostMenuButton("solved", function (attrs) {
      var canAccept = attrs.can_accept_answer;
      var canUnaccept = attrs.can_unaccept_answer;
      var accepted = attrs.accepted_answer;
      var isOp = currentUser && currentUser.id === attrs.topicCreatedById;
      var position = !accepted && canAccept && !isOp ? "second-last-hidden" : "first";

      if (canAccept) {
        return {
          action: "acceptAnswer",
          icon: "far-check-square",
          className: "unaccepted",
          title: "solved.accept_answer",
          label: "solved.solution",
          position: position
        };
      } else if (canUnaccept && accepted) {
        var title = canUnaccept ? "solved.unaccept_answer" : "solved.accepted_answer";
        return {
          action: "unacceptAnswer",
          icon: "check-square",
          title: title,
          className: "accepted fade-out",
          position: position,
          label: "solved.solution"
        };
      } else if (!canAccept && accepted) {
        return {
          className: "hidden",
          disabled: "true",
          position: position,
          beforeButton: function beforeButton(h) {
            return h("span.accepted-text", {
              title: _I18n.default.t("solved.accepted_description")
            }, [h("span", (0, _iconLibrary.iconNode)("check")), h("span.accepted-label", _I18n.default.t("solved.solution"))]);
          }
        };
      }
    });
    api.decorateWidget("post-contents:after-cooked", function (dec) {
      if (dec.attrs.post_number === 1) {
        var postModel = dec.getModel();

        if (postModel) {
          var topic = postModel.topic;

          if (topic.accepted_answer) {
            var hasExcerpt = !!topic.accepted_answer.excerpt;
            var withExcerpt = "\n            <aside class='quote accepted-answer' data-post=\"".concat(topic.get("accepted_answer").post_number, "\" data-topic=\"").concat(topic.id, "\">\n              <div class='title'>\n                ").concat(topic.acceptedAnswerHtml, " <div class=\"quote-controls\"></div>\n              </div>\n              <blockquote>\n                ").concat(topic.accepted_answer.excerpt, "\n              </blockquote>\n            </aside>");
            var withoutExcerpt = "\n            <aside class='quote accepted-answer'>\n              <div class='title title-only'>\n                ".concat(topic.acceptedAnswerHtml, "\n              </div>\n            </aside>");
            var cooked = new _postCooked.default({
              cooked: hasExcerpt ? withExcerpt : withoutExcerpt
            }, dec);
            return dec.rawHtml(cooked.init());
          }
        }
      }
    });
    api.attachWidgetAction("post", "acceptAnswer", function () {
      var _this = this;

      var post = this.model;
      var current = post.get("topic.postStream.posts").filter(function (p) {
        return p.post_number === 1 || p.accepted_answer;
      });
      acceptPost(post);
      current.forEach(function (p) {
        return _this.appEvents.trigger("post-stream:refresh", {
          id: p.id
        });
      });
    });
    api.attachWidgetAction("post", "unacceptAnswer", function () {
      var post = this.model;
      var op = post.get("topic.postStream.posts").find(function (p) {
        return p.post_number === 1;
      });
      unacceptPost(post);
      this.appEvents.trigger("post-stream:refresh", {
        id: op.id
      });
    });

    if (api.registerConnectorClass) {
      api.registerConnectorClass("user-activity-bottom", "solved-list", {
        shouldRender: function shouldRender(args, component) {
          return component.siteSettings.solved_enabled;
        }
      });
      api.registerConnectorClass("user-summary-stat", "solved-count", {
        shouldRender: function shouldRender(args, component) {
          return component.siteSettings.solved_enabled && args.model.solved_count > 0;
        },
        setupComponent: function setupComponent() {
          this.set("classNames", ["linked-stat"]);
        }
      });
    }
  }

  var _default = {
    name: "extend-for-solved-button",
    initialize: function initialize() {
      _topic.default.reopen({
        // keeping this here cause there is complex localization
        acceptedAnswerHtml: (0, _object.computed)("accepted_answer", "id", function () {
          var username = this.get("accepted_answer.username");
          var postNumber = this.get("accepted_answer.post_number");

          if (!username || !postNumber) {
            return "";
          }

          return _I18n.default.t("solved.accepted_html", {
            icon: (0, _iconLibrary.iconHTML)("check-square", {
              class: "accepted"
            }),
            username_lower: username.toLowerCase(),
            username: (0, _utilities.formatUsername)(username),
            post_path: "".concat(this.url, "/").concat(postNumber),
            post_number: postNumber,
            user_path: _user.default.create({
              username: username
            }).path
          });
        })
      });

      _topicStatus.default.reopen({
        statuses: (0, _object.computed)(function () {
          var results = this._super.apply(this, arguments);

          if (this.topic.has_accepted_answer) {
            results.push({
              openTag: "span",
              closeTag: "span",
              title: _I18n.default.t("topic_statuses.solved.help"),
              icon: "far-check-square",
              key: "solved"
            });
          } else if (this.topic.can_have_answer && this.siteSettings.solved_enabled && this.siteSettings.empty_box_on_unsolved) {
            results.push({
              openTag: "span",
              closeTag: "span",
              title: _I18n.default.t("solved.has_no_accepted_answer"),
              icon: "far-square"
            });
          }

          return results;
        })
      });

      (0, _pluginApi.withPluginApi)("0.1", initializeWithApi);
      (0, _pluginApi.withPluginApi)("0.8.10", function (api) {
        api.replaceIcon("notification.solved.accepted_notification", "check-square");
      });
      (0, _pluginApi.withPluginApi)("0.11.0", function (api) {
        api.addAdvancedSearchOptions({
          statusOptions: [{
            name: _I18n.default.t("search.advanced.statuses.solved"),
            value: "solved"
          }, {
            name: _I18n.default.t("search.advanced.statuses.unsolved"),
            value: "unsolved"
          }]
        });
      });
      (0, _pluginApi.withPluginApi)("0.11.7", function (api) {
        api.addSearchSuggestion("status:solved");
        api.addSearchSuggestion("status:unsolved");
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-solved/discourse/pre-initializers/extend-category-for-solved", ["exports", "discourse/models/category", "@ember/object"], function (_exports, _category, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "extend-category-for-solved",
    before: "inject-discourse-objects",
    initialize: function initialize() {
      _category.default.reopen({
        enable_accepted_answers: (0, _object.computed)("custom_fields.enable_accepted_answers", {
          get: function get(fieldName) {
            return (0, _object.get)(this.custom_fields, fieldName) === "true";
          }
        })
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-solved/discourse/routes/user-activity-solved", ["exports", "discourse/routes/user-activity-stream", "I18n"], function (_exports, _userActivityStream, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userActivityStream.default.extend({
    userActionType: 15,
    noContentHelpKey: "solved.no_solutions",
    emptyState: function emptyState() {
      var user = this.modelFor("user");
      var title, body;

      if (this.isCurrentUser(user)) {
        title = _I18n.default.t("solved.no_solved_topics_title");
        body = _I18n.default.t("solved.no_solved_topics_body");
      } else {
        title = _I18n.default.t("solved.no_solved_topics_title_others", {
          username: user.username
        });
        body = "";
      }

      return {
        title: title,
        body: body
      };
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-solved/discourse/solved-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    resource: "user.userActivity",
    map: function map() {
      this.route("solved");
    }
  };
  _exports.default = _default;
});

